import { useTranslation } from "react-i18next";
import { ProjectManagers } from "./ProjectManagers";
import { useGetUsersOrgsData } from "../../../NavBar/useGetUsersOrgsDataQuery";

export const OrganizationProjectManagersSettings = () => {
  const { t } = useTranslation("settings", { keyPrefix: "projectManagers" });
  const usersOrganizations = useGetUsersOrgsData();

  const currentOrganization = usersOrganizations?.organizations.find(
    (org) => org.name === usersOrganizations?.currentOrganization
  );

  return (
    <div className={"h-full w-full flex flex-col p-4"}>
      <span className={"text-gray"}>{currentOrganization?.name}</span>
      <span className={"text-3xl mb-2"}>{t("projectManagers")}</span>
      <span className={"text-gray"}>{t("projectManagersDescription")}</span>
      <ProjectManagers />
    </div>
  );
};
