import { useGetUsersOrgsData } from "../../../NavBar/useGetUsersOrgsDataQuery";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { IntegrationUserDialog } from "./IntegrationUserDialog";
import { Button } from "@tocoman/ui";
import { OrgInfoForm } from "./OrgInfoForm";

export type OptionalOrganizationData = {
  name?: string | null;
  companyName?: string | null;
  description?: string | null;
  defaultVAT: number;
};

export const OrganizationSettings = () => {
  const { t } = useTranslation("settings");

  const [integrationOpen, setIntegrationOpen] = useState(false);

  const usersOrganizations = useGetUsersOrgsData();

  const currentOrganization = usersOrganizations?.organizations.find(
    (org) => org.name === usersOrganizations?.currentOrganization
  );

  return (
    <div className={"h-full w-full flex flex-col p-4"}>
      <span className={"text-gray"}>{currentOrganization?.name}</span>
      <span className={"text-3xl mb-2"}>{t("settings")}</span>
      <span className={"text-gray"}>{t("settingsDescription")}</span>

      <OrgInfoForm currentOrganization={currentOrganization} />
      <div className={"mt-4"} />
      {currentOrganization?.integrationEnabled && (
        <Button
          className={"w-auto self-start my-5"}
          onClick={() => setIntegrationOpen(true)}
        >
          {t("integrationButton")}
        </Button>
      )}
      {integrationOpen && (
        <IntegrationUserDialog
          open={integrationOpen}
          onClose={() => setIntegrationOpen(false)}
        />
      )}
    </div>
  );
};
