import { useRef, useState, useEffect } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import "@mescius/activereportsjs/pdfexport";
import "@mescius/activereportsjs/htmlexport";
import "@mescius/activereportsjs/tabulardataexport";
import "@mescius/activereportsjs/xlsxexport";
import "@mescius/activereportsjs/styles/ar-js-ui.css";
import "@mescius/activereportsjs/styles/ar-js-viewer.css";
import { Core } from "@mescius/activereportsjs";
import { Rdl as ARJS } from "@mescius/activereportsjs/core";
import { Loader, ToggleVisibilityButton, IconLtV1 } from "@tocoman/ui";
import { Viewer } from "@mescius/activereportsjs-react";
import { StringCombobox } from "../../../components/StringCombobox";
import {
  useGetReportTemplateNames,
  useGetReportTemplateByName,
} from "./ReportHooks";
import { ReportViewProps } from "./types";
import { getAccessToken } from "src/client-ts/auth/accessToken";
import { setParameterValues } from "./utils";
import { ParameterValueMap } from "./types";
import { reportCustomCodes } from "../../SuperAdmin/ReportDesigner/CustomFunctions";

Core.CustomCode.registerFunctions(reportCustomCodes);

export type ReportSelectionForm = {
  reportName?: string;
};

export const Report = ({ projectId }: ReportViewProps) => {
  const { t } = useTranslation("reports");
  const methods = useForm<ReportSelectionForm>({
    defaultValues: {
      reportName: "C200",
    },
  });
  const viewerRef = useRef<Viewer>(null);
  const targetRef = useRef<HTMLDivElement>(null);

  const { watch } = methods;

  const activeReportName = watch("reportName");

  const [token, setToken] = useState("");
  const listResults = useGetReportTemplateNames();
  const reportResults = useGetReportTemplateByName(activeReportName);
  const { data: report } = reportResults;

  const isDataLoading = listResults.isLoading || reportResults.isLoading;

  useEffect(() => {
    async function getToken() {
      const token = await getAccessToken();
      setToken(token);
    }
    getToken();
  }, []);

  const initialValues: ParameterValueMap = {
    projectId: projectId.toString(),
    token,
  };

  const initialReportTemplate: ARJS.Report = {
    ...report,
    ReportParameters:
      setParameterValues(report?.ReportParameters || [], initialValues) || [],
  };

  const renderReport = () => {
    if (viewerRef.current) {
      viewerRef.current.Viewer.open(initialReportTemplate, {});
    }
  };

  useEffect(() => {
    if (initialReportTemplate) {
      renderReport();
    }
  }, [initialReportTemplate]);

  return (
    <div className={"flex h-full w-full"}>
      <div className={"flex flex-row w-full"}>
        <div className={"flex flex-col gap-4 pr-3 w-auto"}>
          <div className="flex flex-row items-start gap-5 min-h-full max-h-full ml-5 overflow-y-auto">
            <div ref={targetRef}>
              <div className="flex flex-col gap-5 w-[250px] m-3 h-full">
                <FormProvider {...methods}>
                  {!isDataLoading && (
                    <Controller
                      name="reportName"
                      render={({ field: { onChange, value } }) => (
                        <StringCombobox
                          label={t("report")}
                          initialValue={value}
                          items={listResults.data || []}
                          onValueChange={onChange}
                        />
                      )}
                    />
                  )}
                </FormProvider>
              </div>
            </div>
            <div className="border-l border-l-light min-h-full ml-2">
              <ToggleVisibilityButton
                icon={IconLtV1}
                targetRef={targetRef}
                className="ml-[-12px] mt-3"
              />
            </div>
          </div>
        </div>
        <div className={"w-full h-full"}>
          {isDataLoading && <Loader testId={"basic-info-loader"} />}
          {!isDataLoading && report && (
            <div className={"w-full h-full"}>
              <Viewer theme="ActiveReports" ref={viewerRef} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
