import { Rdl as ARJS } from "@mescius/activereportsjs/core";
import { ParameterValueMap } from "../../../Estimation/Report/types";
import { setParameterValues } from "../../../Estimation/Report/utils";

const parameterSchema: Record<string, Omit<ARJS.ReportParameter, "Name">> = {
  token: {
    DataType: "String",
    Hidden: true,
    DefaultValue: {
      Values: [],
    },
  },
  projectId: {
    DataType: "String",
    Hidden: true,
    DefaultValue: {
      Values: [],
    },
  },
};

const createEmptyStringParameter = (Name: string): ARJS.ReportParameter => ({
  ...parameterSchema[Name],
  Name,
});

const addMissingParameters = (
  parameters: ARJS.ReportParameter[],
  parameterNames: string[]
) => [...parameters, ...parameterNames.map(createEmptyStringParameter)];

export const setParameterInitialValues = (
  parameters: ARJS.ReportParameter[],
  values: ParameterValueMap
) => {
  const missingParameters = Object.keys(values).filter(
    (parameterName) => !parameters?.some(({ Name }) => Name === parameterName)
  );

  const completedParameterList = addMissingParameters(
    parameters,
    missingParameters
  );

  return setParameterValues(completedParameterList, values);
};
