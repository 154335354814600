import { AxiosError } from "axios";
import { useQuery } from "react-query";
import axiosRestApiClient from "src/client-ts/axiosRestApiConfig";
import { reportError } from "src/client-ts/utils/error";

export type ResourceDto = {
  id: number;
  workItemId: number;
  workItemCode: string;
  workItemDescription: string | null;
  workItemUnit: string | null;
  subProjectCode: string;
  name: string | null;
  unit: string | null;
  costType: string | null;
  price: number | null;
  consumption: number | null;
  additionalPercentage: number | null;
  discountPercentage: number;
  index: number | null;
  vatPercentage: number | null;
  group: string | null;
  resourceGroup: string | null;
  sortingNumber: string | null;
  supplier: string | null;
  supplierCode: string | null;
  internalPricelistId: number | null;
  costGroup: string | null;
  calculations: ResourceCalculationsDto;
  locations: ResourceLocationDto[];
  eanCode: string | null;
  salesBatchUnit: string | null;
  salesBatchNetContent: number | null;
};

export type ResourceCalculationsDto = {
  workItemQuantity: number;
  quantity: number;
  price: number;
  pricePerUnit: number;
  pricePerQuantity: number;
  productionRate: number | undefined;
  totalPrice: number;
  totalPriceWithDiscount: number;
};

export type LocationDto = {
  code: string;
  description: string | null;
};

export type ResourceLocationDto = {
  code: string;
  quantity: number;
  price: number;
};

export type ProjectDto = {
  code: string;
  name: string;
  version: string;
  currency: string;
};

export type PagedResponse<T> = {
  results: T[];
};

export function useAllResourcesDetailsQuery(
  projectId: number,
  includeSocialExpenses: boolean
) {
  const queryUrl = `/api/v1/estima/projects/${projectId}/resources?include=locations&include=calculations&includeSocialExpenses=${includeSocialExpenses}&limit=10000`;
  return useQuery({
    queryKey: queryUrl,
    queryFn: async () => {
      const { data } = await axiosRestApiClient.get(`${queryUrl}`);
      return data;
    },
    onError: (error: AxiosError) => {
      reportError("Error while fetching resources from REST API", error);
    },
  });
}

export function useProjectDetailsQuery(projectId: number) {
  const queryUrl = `/api/v1/estima/projects/${projectId}`;
  return useQuery({
    queryKey: queryUrl,
    queryFn: async () => {
      const { data } = await axiosRestApiClient.get(`${queryUrl}`);
      return data;
    },
    onError: (error: AxiosError) => {
      reportError("Error while fetching project details from REST API", error);
    },
  });
}

export function useProjectLocationsQuery(projectId: number) {
  const queryUrl = `/api/v1/estima/projects/${projectId}/locations?limit=10000`;
  return useQuery({
    queryKey: queryUrl,
    queryFn: async () => {
      const { data } = await axiosRestApiClient.get(`${queryUrl}`);
      return data;
    },
    onError: (error: AxiosError) => {
      reportError("Error while fetching locations from REST API", error);
    },
  });
}
