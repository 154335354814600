import { IconButton } from "@mui/material";
import { ChangeEvent } from "react";

import "./style.css";
import { Icon, IconPencil } from "@tocoman/ui";

export type RadioGroupItem = {
  value: string | undefined;
  label: string | undefined;
  description: string | undefined;
  checked: boolean | undefined;
  key: string | undefined;
};
type NavbarRadioGroupProps = {
  items: RadioGroupItem[];
  onValueChange: (event: ChangeEvent<HTMLInputElement>) => void;
  isAdmin?: boolean | undefined;
  className?: string;
};

type CustomRadioProps = {
  label?: string;
  value: string;
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

const CustomRadio = ({ value, checked, onChange }: CustomRadioProps) => {
  return (
    <label className="custom-radio-label">
      <input
        type="radio"
        className="custom-radio-input"
        value={value}
        checked={checked}
        onChange={onChange}
      />

      <div className="custom-radio-image self-center">
        {checked && (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12.5" r="9" fill="#1C375A" />
            <circle cx="12" cy="12.5" r="9" fill="#1C46D6" />
            <circle cx="12" cy="12.5" r="3" fill="white" />
          </svg>
        )}
        {!checked && (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12.5" r="9" fill="lightgray" />
          </svg>
        )}
      </div>
    </label>
  );
};

export const NavbarRadioGroup = ({
  items,
  onValueChange,
  isAdmin,
}: NavbarRadioGroupProps) => {
  const OrganizationLabel = (item: RadioGroupItem) => {
    return (
      <div className={"flex justify-between items-center w-full px-2 my-2"}>
        <div className={"w-full flex gap-2"}>
          <div className={"items-center flex"}>
            <CustomRadio
              value={item.value ?? ""}
              checked={item.checked ?? false}
              onChange={onValueChange}
            />
          </div>
          <div>
            <p className={"text-[16px] font-[600]"}>{item.label}</p>
            <p className={"text-[12px] font-[400]"}>{item.description}</p>
          </div>
        </div>
        <div className={"content-center h-full"}>
          {item.checked && isAdmin && (
            <IconButton
              onClick={() =>
                (window.location.href = "/new/settings/organization/general")
              }
            >
              <Icon
                icon={IconPencil}
                scale={1.2}
                className={"self-center text-[#77828F]"}
              />
            </IconButton>
          )}
        </div>
      </div>
    );
  };
  return (
    <div>
      {items.map((item) => {
        return <OrganizationLabel {...item} key={item.key} />;
      })}
    </div>
  );
};
