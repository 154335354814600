import ExcelJS from "exceljs";
import { downloadBlob } from "./downloadBlob";

export const downloadBuffer = (
  buffer: ExcelJS.Buffer,
  fileName: string
): void => {
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  downloadBlob(blob, fileName);
};
