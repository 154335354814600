import {
  Header,
  Icon,
  IconChevronDownV1,
  IconChevronRightV1,
  IconLtDisabledV1,
  IconOrganization,
  IconUser,
} from "@tocoman/ui";
import { useTranslation } from "react-i18next";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFeatureEnabled } from "src/client-ts/split/useFeatureEnabled";
import { FeatureFlags } from "src/client-ts/split/FeatureFlags";

export const SettingsNav = () => {
  const { t } = useTranslation("settings", { keyPrefix: "nav" });
  const location = useLocation();
  const navigate = useNavigate();
  const [history, setHistory] = useState<string[]>([]);

  const organizationCurrencyEnabled = useFeatureEnabled(
    FeatureFlags.ORGANIZATION_CURRENCY
  );

  // Save history of visited pages in new settings
  useEffect(() => {
    setHistory((prevHistory) => [...prevHistory, location.pathname]);
  }, [location]);

  // Skip the pages that are already visited in settings that you can go back to the page you came from
  const pagesToSkip = history.length;
  const isActiveLink = (pathname: string) => pathname === location.pathname;

  const [
    organizationSubLinksVisible,
    setOrganizationSubLinksVisible,
  ] = useState(true);

  const organizationSubLinks = [
    <button
      key={"generalSettings"}
      className={
        "text-black flex w-full text-left items-center text-xl gap-3 mb-1"
      }
      style={
        isActiveLink("/new/settings/organization/general")
          ? { color: "#2845CE" }
          : {}
      }
    >
      <Link to={"/new/settings/organization/general"}>
        {t("generalSettings")}
      </Link>
    </button>,
    organizationCurrencyEnabled ? (
      <button
        key={"currency"}
        className={
          "text-black flex w-full text-left items-center text-xl gap-3 mb-1"
        }
        style={
          isActiveLink("/new/settings/organization/currency")
            ? { color: "#2845CE" }
            : {}
        }
      >
        <Link to={"/new/settings/organization/currency"}>
          {t("currencies")}
        </Link>
      </button>
    ) : null,
    <button
      key={"projectManagers"}
      className={
        "text-black flex w-full text-left items-center text-xl gap-3 mb-1"
      }
      style={
        isActiveLink("/new/settings/organization/projectManagers")
          ? { color: "#2845CE" }
          : {}
      }
    >
      <Link to={"/new/settings/organization/projectManagers"}>
        {t("projectManagers")}
      </Link>
    </button>,
  ];

  return (
    <div className={"w-[225px] border-r border-r-light h-full p-5"}>
      <button onClick={() => navigate(-pagesToSkip)} className={"mb-8"}>
        <Icon icon={IconLtDisabledV1} />
      </button>
      <Header title={t("settings")} titleSize={"medium"} />
      <button
        className={
          "text-black flex w-full text-left items-center text-xl gap-3 mb-2 justify-between"
        }
        onClick={() =>
          setOrganizationSubLinksVisible(!organizationSubLinksVisible)
        }
      >
        <div className={"flex gap-3"}>
          <Icon icon={IconOrganization} scale={1.2} />
          <span>{t("organization")}</span>
        </div>
        {organizationSubLinksVisible ? (
          <Icon icon={IconChevronDownV1} className={"text-dark"} />
        ) : (
          <Icon icon={IconChevronRightV1} className={"text-dark"} />
        )}
      </button>
      {organizationSubLinksVisible && (
        <div className={"ml-5"}>
          {organizationSubLinks.map((subLink) =>
            subLink ? <div key={subLink.key}>{subLink}</div> : null
          )}
        </div>
      )}

      <button
        className={
          "text-black flex w-full text-left items-center text-xl gap-3 mt-3"
        }
        style={isActiveLink("/new/settings/users") ? { color: "#2845CE" } : {}}
      >
        <Icon icon={IconUser} />
        <Link to={"/new/settings/users"}>{t("users")}</Link>
      </button>
    </div>
  );
};
