// eslint-disable-next-line no-restricted-imports
import axios from "axios";
import { getAccessToken } from "./auth/accessToken";
import { getClientConfigVarOptional } from "src/client/config";

const axiosRestApiClient = axios.create({});

axiosRestApiClient.interceptors.request.use(async (config) => {
  config.baseURL = getClientConfigVarOptional("CLIENT_REST_API_URL");
  config.headers.Authorization = `Bearer ${await getAccessToken()}`;
  return config;
});

export default axiosRestApiClient;
