import ExcelJS from "exceljs";
import { Button, IconFileDownloadV1 } from "@tocoman/ui";
import { TFunction, useTranslation } from "react-i18next";
import { ProjectId } from "../../../../../ts-bindings/ProjectId";
import { downloadBuffer } from "../../../utils/downloadBuffer";

import {
  LocationDto,
  PagedResponse,
  ProjectDto,
  ResourceDto,
  ResourceLocationDto,
  useAllResourcesDetailsQuery,
  useProjectDetailsQuery,
  useProjectLocationsQuery,
} from "./useResourcesQueries";

type Props = {
  projectId: ProjectId;
  includeSocialExpenses: boolean;
};

type ResourceExcelBaseRow = {
  costGroupCode: string;
  workItemCode: string;
  workItemDescription: string;
  workItemQuantity: string | number;
  workItemUnit: string;
  costType: string;
  resourceGroup: string;
  sequenceNumber: string | number;
  id: string | number;
  name: string;
  consumptionRate: string | number;
  wastePercentage: string | number;
  resourceQuantitity: string | number;
  resourceUnit: string;
  priceWithoutDiscount: string | number;
  pricePerUnit: string | number;
  discountPercentage: string | number;
  priceInclDiscount: string | number;
  total: string | number;
  currency: string;
  index: string | number;
  vatPercentage: string | number;
  eanCode: string;
  internalId: string | number;
  supplier: string;
  supplierCode: string;
  salesBatchUnit: string;
  salesBatchNetContent: string | number;
  projectCode: string;
  version: string;
  subProjectCode: string;
};
/**
 * Convenience function to get excel row values as array
 * since Object.values() does not promise static property order
 */
function getBaseRowAsOrderedArray(data: ResourceExcelBaseRow) {
  return [
    data.costGroupCode,
    data.workItemCode,
    data.workItemDescription,
    data.workItemQuantity,
    data.workItemUnit,
    data.costType,
    data.resourceGroup,
    data.sequenceNumber,
    data.id,
    data.name,
    data.consumptionRate,
    data.wastePercentage,
    data.resourceQuantitity,
    data.resourceUnit,
    data.priceWithoutDiscount,
    data.pricePerUnit,
    data.discountPercentage,
    data.priceInclDiscount,
    data.total,
    data.currency,
    data.index,
    data.vatPercentage,
    data.eanCode,
    data.internalId,
    data.supplier,
    data.supplierCode,
    data.salesBatchUnit,
    data.salesBatchNetContent,
    data.projectCode,
    data.version,
    data.subProjectCode,
  ];
}

const exportResourcesByLocationsExcel = async (
  t: TFunction,
  includeSocialExpenses: boolean,
  resourcesWithLocationsResponse: PagedResponse<ResourceDto>,
  projectDetailsResponse: ProjectDto,
  locationsResponse: PagedResponse<LocationDto>
) => {
  const projectDetailsData: ProjectDto = projectDetailsResponse as ProjectDto;
  const projectLocationsData = locationsResponse.results as LocationDto[];
  const resourcesWithLocationsData = resourcesWithLocationsResponse.results as ResourceDto[];

  const workbook = new ExcelJS.Workbook();
  const quantityWorksheet = workbook.addWorksheet(
    t("resourcesQuantityByLocation")
  );
  const costsWorksheet = workbook.addWorksheet(t("resourcesCostsByLocation"));

  /** Add headers */
  quantityWorksheet.addRow([
    `${t("resourcesQuantityByLocation")} - ${new Date().toLocaleDateString(
      "fi-FI"
    )}`,
  ]);
  quantityWorksheet.addRow([
    `${projectDetailsData.name}, v. ${projectDetailsData.version}`,
  ]);
  quantityWorksheet.addRow([
    includeSocialExpenses
      ? t("includeSocialExpenses")
      : t("excludeSocialExpenses"),
  ]);
  quantityWorksheet.addRow([]);

  costsWorksheet.addRow([
    `${t("resourcesCostsByLocation")} - ${new Date().toLocaleDateString(
      "fi-FI"
    )}`,
  ]);
  costsWorksheet.addRow([
    `${projectDetailsData.name}, v. ${projectDetailsData.version}`,
  ]);
  costsWorksheet.addRow([
    includeSocialExpenses
      ? t("includeSocialExpenses")
      : t("excludeSocialExpenses"),
  ]);
  costsWorksheet.addRow([]);

  const headerRow: ResourceExcelBaseRow = {
    costGroupCode: t("costGroupCode"),
    workItemCode: t("workItemCode"),
    workItemDescription: t("workItemDescription"),
    workItemQuantity: t("workItemQuantity"),
    workItemUnit: t("workItemUnit"),
    costType: t("RT"),
    resourceGroup: t("resourceGroup"),
    sequenceNumber: t("sequenceNumber"),
    id: t("id"),
    name: t("rtName"),
    consumptionRate: t("consumptionRate"),
    wastePercentage: t("wastePercentage"),
    resourceQuantitity: t("resourceQuantity"),
    resourceUnit: t("resourceUnit"),
    priceWithoutDiscount: t("discountedPrice"),
    pricePerUnit: t("pricePerUnit"),
    discountPercentage: t("discountPercentage"),
    priceInclDiscount: t("priceInclDiscount"),
    total: t("total"),
    currency: t("currency"),
    index: t("index"),
    vatPercentage: t("vatPercentage"),
    eanCode: t("eanCode"),
    internalId: t("internalId"),
    supplier: t("supplier"),
    supplierCode: t("supplierCode"),
    salesBatchUnit: t("salesBatchUnit"),
    salesBatchNetContent: t("salesBatchNetContent"),
    projectCode: t("project"),
    version: t("version"),
    subProjectCode: t("subproject"),
  };

  const locationColumns = projectLocationsData.map((location: LocationDto) => {
    return location.code;
  });

  quantityWorksheet.addRow([
    ...getBaseRowAsOrderedArray(headerRow),
    ...locationColumns,
  ]);
  costsWorksheet.addRow([
    ...getBaseRowAsOrderedArray(headerRow),
    ...locationColumns,
  ]);

  /** Add data rows */
  resourcesWithLocationsData.forEach((resource: ResourceDto) => {
    const baseRow: ResourceExcelBaseRow = {
      costGroupCode: resource.costGroup ?? "",
      workItemCode: resource.workItemCode,
      workItemDescription: resource.workItemDescription ?? "",
      workItemQuantity: resource.calculations.workItemQuantity,
      workItemUnit: resource.workItemUnit ?? "",
      costType: resource.costType ?? "",
      resourceGroup: resource.group ?? "",
      sequenceNumber: resource.sortingNumber ?? "",
      id: resource.id,
      name: resource.name ?? "",
      consumptionRate: resource.consumption ?? "",
      wastePercentage: resource.additionalPercentage ?? "",
      resourceQuantitity: resource.calculations.quantity,
      resourceUnit: resource.unit ?? "",
      priceWithoutDiscount: resource.calculations.totalPrice,
      pricePerUnit: resource.calculations.pricePerUnit,
      discountPercentage: resource.discountPercentage,
      priceInclDiscount: resource.calculations.totalPriceWithDiscount,
      total: resource.calculations.totalPrice,
      currency: projectDetailsData.currency,
      index: resource.index ?? "",
      vatPercentage: resource.vatPercentage ?? "",
      eanCode: resource.eanCode ?? "",
      internalId: resource.internalPricelistId ?? "",
      supplier: resource.supplier ?? "",
      supplierCode: resource.supplierCode ?? "",
      salesBatchUnit: resource.salesBatchUnit ?? "",
      salesBatchNetContent: resource.salesBatchNetContent ?? "",
      projectCode: projectDetailsData.code,
      version: projectDetailsData.version,
      subProjectCode: resource.subProjectCode,
    };

    const quantitiesByLocations = projectLocationsData.map(
      (location: LocationDto) => {
        const resourceLocation = resource.locations.find(
          (l: ResourceLocationDto) => l.code === location.code
        );
        return resourceLocation ? resourceLocation.quantity : 0;
      }
    );

    const pricesByLocations = projectLocationsData.map(
      (location: LocationDto) => {
        const resourceLocation = resource.locations.find(
          (l: ResourceLocationDto) => l.code === location.code
        );
        return resourceLocation ? resourceLocation.price : 0;
      }
    );

    quantityWorksheet.addRow([
      ...getBaseRowAsOrderedArray(baseRow),
      ...quantitiesByLocations,
    ]);
    costsWorksheet.addRow([
      ...getBaseRowAsOrderedArray(baseRow),
      ...pricesByLocations,
    ]);
  });

  const buffer = await workbook.xlsx.writeBuffer();
  const filename = `${projectDetailsData.name}-${projectDetailsData.version}`;
  downloadBuffer(buffer, filename);
};

export const ResourcesByLocationExportButton = ({
  projectId,
  includeSocialExpenses,
}: Props) => {
  const { t } = useTranslation("reports", {
    keyPrefix: "resourcesByLocationsExcelExport",
  });
  const { data: resourcesData } = useAllResourcesDetailsQuery(
    projectId,
    includeSocialExpenses
  );
  const { data: projectDetailsData } = useProjectDetailsQuery(projectId);
  const { data: locationsData } = useProjectLocationsQuery(projectId);

  const handleOnClick = () => {
    exportResourcesByLocationsExcel(
      t,
      includeSocialExpenses,
      resourcesData,
      projectDetailsData,
      locationsData
    );
  };

  /** Use two buttons since single button with icon and text broke the button layout. This has probably something to do with surrounding purescript view. */
  return (
    <div onClick={handleOnClick} className="flex">
      <Button icon={IconFileDownloadV1} variant={"text"} />
      <Button variant={"text"}>{t("exportToExcel")}</Button>
    </div>
  );
};
