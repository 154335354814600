import { Rdl as ARJS } from "@mescius/activereportsjs/core";
import { ParameterValueMap } from "./types";

const setParameterValue = (
  parameter: ARJS.ReportParameter,
  value: string
): ARJS.ReportParameter => ({
  ...parameter,
  DefaultValue: { Values: [value] },
});

export const setParameterValues = (
  parameters: ARJS.ReportParameter[],
  values: ParameterValueMap
) =>
  parameters.map((parameter) =>
    setParameterValue(parameter, values[parameter.Name] || "")
  );
