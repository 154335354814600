import { Table } from "../../../../components/Table";
import { Button, IconDelete24Px, IconEditV1, Input, Modal } from "@tocoman/ui";
import React, { useCallback, useMemo, useState } from "react";

import { ColDef, ICellRendererParams } from "ag-grid-community";
import { useTranslation } from "react-i18next";
import { RegisterOptions, useForm } from "react-hook-form";
import {
  ProjectManager,
  useAddProjectManagerQuery,
  useDeleteProjectManagerQuery,
  useEditProjectManagerQuery,
  useGetProjectManagersQuery,
} from "./useProjectManagersQuery";

export const ProjectManagers = () => {
  const { t } = useTranslation("settings", { keyPrefix: "projectManagers" });

  const { data: projectManagers } = useGetProjectManagersQuery();
  const managersRowData = useMemo(() => {
    return projectManagers?.map((pm) => {
      return { name: pm };
    });
  }, [projectManagers]);

  const addPm = useAddProjectManagerQuery();
  const editProjectManager = useEditProjectManagerQuery();
  const deleteProjectManager = useDeleteProjectManagerQuery();

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [pmToEdit, setPmToEdit] = useState<{
    newPm: string | undefined;
    oldPm: string | undefined;
  }>({ newPm: undefined, oldPm: undefined });
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [pmToDelete, setPmToDelete] = useState<string | undefined>(undefined);
  const [inUseEditErrorMessage, setInUseEditErrorMessage] = useState(false);

  const methods = useForm<ProjectManager>();

  const { register, handleSubmit, reset, setValue } = methods;

  const [inUseErrorMessage, setInUseErrorMessage] = useState(false);

  const mkInputProps = (
    field: keyof ProjectManager,
    registerOptions?: RegisterOptions<ProjectManager, typeof field>
  ) => ({
    label: t(field),
    ...register(field, registerOptions),
  });

  const handleAddPm = (data: ProjectManager) => {
    if (data.pm && projectManagers?.includes(data.pm)) {
      setInUseErrorMessage(true);
      return;
    }
    addPm.mutate(data);
    reset();
  };

  const handleEditChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      e.target.value !== pmToEdit.oldPm &&
      projectManagers?.includes(e.target.value)
    ) {
      setInUseEditErrorMessage(true);
    } else {
      setInUseEditErrorMessage(false);
    }
    setPmToEdit({ ...pmToEdit, newPm: e.target.value });
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
    setPmToEdit({ newPm: undefined, oldPm: undefined });
    setInUseEditErrorMessage(false);
  };

  const handleEditPm = () => {
    if (pmToEdit.newPm && projectManagers?.includes(pmToEdit.newPm)) {
      setInUseEditErrorMessage(true);
      return;
    }
    if (pmToEdit.newPm && pmToEdit.oldPm) {
      const values = {
        newPM: pmToEdit.newPm,
        oldPM: pmToEdit.oldPm,
      };
      editProjectManager.mutate(values);
      closeEditModal();
    }
  };

  const handleDelete = () => {
    if (!pmToDelete) return;
    deleteProjectManager.mutate({ pm: pmToDelete });
    closeDeleteModal();
  };

  const closeDeleteModal = () => {
    setPmToDelete(undefined);
    setDeleteModalOpen(false);
  };

  const editColRenderer = useCallback((cellData: ICellRendererParams) => {
    return (
      <Button
        className={"w-auto h-full self-center"}
        icon={IconEditV1}
        variant={"text"}
        onClick={() => {
          setEditModalOpen(true);
          setPmToEdit({ newPm: cellData.data.name, oldPm: cellData.data.name });
        }}
      >
        {t("edit")}
      </Button>
    );
  }, []);

  const deleteColRenderer = useCallback((cellData: ICellRendererParams) => {
    return (
      <Button
        className={"w-auto h-full self-center text-red-600"}
        icon={IconDelete24Px}
        variant={"text"}
        color={"danger"}
        onClick={() => {
          setDeleteModalOpen(true);
          setPmToDelete(cellData.data.name);
        }}
      >
        {t("delete")}
      </Button>
    );
  }, []);

  const columnDefs: ColDef[] = useMemo(
    () => [
      {
        headerName: t("projectManager"),
        field: "name",
        flex: 1,
        sortable: true,
        filter: "agTextColumnFilter",
        floatingFilter: true,
      },
      {
        colId: "edit",
        headerName: "",
        pinned: "right",
        width: 112,
        filter: false,
        sortable: false,
        lockVisible: true,
        lockPinned: true,
        lockPosition: true,
        resizable: false,
        suppressSizeToFit: true,
        cellRenderer: editColRenderer,
      },
      {
        colId: "delete",
        headerName: "",
        pinned: "right",
        width: 106,
        filter: false,
        sortable: false,
        lockVisible: true,
        lockPinned: true,
        lockPosition: true,
        resizable: false,
        suppressSizeToFit: true,
        cellRenderer: deleteColRenderer,
      },
    ],
    [deleteColRenderer]
  );

  const editModalActions = useMemo(
    () => [
      <div className="ml-auto gap-4 flex" key={"editModal"}>
        <Button
          label={t`cancel`}
          variant="text"
          onClick={() => {
            setEditModalOpen(false);
          }}
          size="large"
        />
        <Button
          label={t`save`}
          size="large"
          type="submit"
          form={"editPmForm"}
          disabled={inUseEditErrorMessage}
        />
      </div>,
    ],
    [pmToEdit]
  );

  const deleteModalActions = useMemo(
    () => [
      <div className="ml-auto gap-4 flex" key={"editModal"}>
        <Button
          label={t`cancel`}
          variant="text"
          onClick={closeDeleteModal}
          size="large"
        />
        <Button
          color={"danger"}
          label={t`delete`}
          onClick={handleDelete}
          size="large"
        />
      </div>,
    ],
    [pmToDelete]
  );
  return (
    <div>
      <Table
        className={"w-full h-[310px] mt-4"}
        columnDefs={columnDefs}
        rowData={managersRowData}
      />
      <form
        className={"flex flex-col gap-3 mt-2"}
        onSubmit={handleSubmit(handleAddPm)}
      >
        <Input
          {...mkInputProps("pm")}
          onChange={(e) => {
            if (projectManagers?.includes(e.target.value)) {
              setInUseErrorMessage(true);
            } else {
              setInUseErrorMessage(false);
            }
            setValue("pm", e.target.value);
          }}
          className={"w-[200px]"}
        />
        {inUseErrorMessage && (
          <span className={"text-red-600"}>{t("inUseErrorMessage")}</span>
        )}
        <Button
          type={"submit"}
          className={"w-auto self-start"}
          disabled={inUseErrorMessage}
        >
          {t("add")}
        </Button>
      </form>
      {editModalOpen && (
        <Modal
          isOpen={editModalOpen}
          closeModal={closeEditModal}
          title={`${t("editProjectManager")} ${pmToEdit.oldPm}`}
          actions={editModalActions}
          width={600}
        >
          <form
            id={"editPmForm"}
            onSubmit={handleEditPm}
            className={"w-[200px] flex flex-col"}
          >
            <Input
              label={t("name")}
              value={pmToEdit.newPm}
              onChange={(e) => {
                handleEditChange(e);
              }}
            />
          </form>
          {inUseEditErrorMessage && (
            <span className={"text-red-600"}>{t("inUseErrorMessage")}</span>
          )}
        </Modal>
      )}
      {deleteModalOpen && (
        <Modal
          isOpen={deleteModalOpen}
          closeModal={closeDeleteModal}
          actions={deleteModalActions}
          title={t("deleteProjectManager")}
        >
          <span>{`${t(
            "deleteProjectManagerConfirmation"
          )} ${pmToDelete}?`}</span>
        </Modal>
      )}
    </div>
  );
};
