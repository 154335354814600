import { Button, Checkbox, Input, Loader, Modal } from "@tocoman/ui";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useWorkerState } from "../../../hooks/useWorkerState";
import { ErrorMessage } from "../../../components/ErrorMessage";
import { StringCombobox } from "src/client-ts/components/StringCombobox";
import { isNumber, isPositive } from "src/client-ts/utils/validations";
import { useMemo, useState } from "react";
import { useDefault } from "../../../hooks/useDefault";
import { OrgClassification } from "../../../../../ts-bindings/OrgClassification";
import { head } from "lodash";
import { FeatureFlags } from "../../../split/FeatureFlags";
import { FeatureFlagContainer } from "../../../split/FeatureFlagContainer";
import { CostGroupClassification } from "../../../../../ts-bindings/CostGroupClassification";
import { LicenseFeature } from "../../../LicenseFeatures/useLicenseFeature";
import { LicenseFeatureContainer } from "../../../LicenseFeatures/LicenseFeatureContainer";
import { useSetNextAvailableVersion } from "../../../hooks/useSetNextAvailableVersion";
import {
  useAllowedCurrenciesQuery,
  useDefaultCurrencyQuery,
} from "../../Admin/Settings/OrganizationSettings/OrganizationCurrencySettings/useOrganizationCurrencySettings";
import { ProjectFormValues } from "./ProjectDetailsModal";
import { useProjectGroups } from "./useProjectGroups";

type BasicInfoProps = {
  newProject: boolean;
  projectId?: number;
};

export const BasicInfoTab = ({ newProject, projectId }: BasicInfoProps) => {
  const { t } = useTranslation("projects", { keyPrefix: "details" });
  const {
    register,
    control,
    setValue,
    resetField,
    watch,
    getFieldState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    formState: { errors },
  } = useFormContext<ProjectFormValues>();

  const [
    reportingPublishWarningOpen,
    setReportingPublishWarningOpen,
  ] = useState(false);
  const [
    reportingUnpublishWarningOpen,
    setReportingUnpublishWarningOpen,
  ] = useState(false);
  const appAccessRights = useWorkerState("UserAppAccessRightsState");
  const ccRights = appAccessRights?.costControl ?? false;

  const {
    data: projectGroups,
    isLoading: projectGroupsLoading,
  } = useProjectGroups();

  const orgClassifications: OrgClassification[] = useDefault(
    useWorkerState("ClassificationsState"),
    []
  );

  const defaultClassifications: OrgClassification[] = useDefault(
    useWorkerState("DefaultClassificationState"),
    []
  );

  const defaultClassification = defaultClassifications[0]
    ? defaultClassifications[0].name
    : undefined;

  const classifications = useMemo(
    () => orgClassifications.map((item) => item.name),
    [orgClassifications]
  );

  const projectDetails = useWorkerState("ProjectDetailsDataState", projectId);

  const {
    data: organizationCurrencies,
    isLoading: currenciesLoading,
  } = useAllowedCurrenciesQuery();

  const {
    data: defaultCurrency,
    isLoading: defaultCurrencyLoading,
  } = useDefaultCurrencyQuery();

  const publishToReportingFeatureFlag = FeatureFlags.PROJECT_PUBLISH_REPORT;
  const publishToTempoFeatureFlag = FeatureFlags.PUBLISH_TO_TEMPO;

  const getValidOrgClassification = (value: string): string | null => {
    return (
      (classifications.includes(value) ? value : head(classifications)) ?? null
    );
  };

  const {
    setNextAvailableVersion,
    versionLoading,
  } = useSetNextAvailableVersion(setValue);

  const watchProductionPlanning = watch("productionPlanning");
  const watchReporting = watch("isReportingProject");
  const watchClassification = watch("classification");

  const costGroupClassifications: CostGroupClassification[] = useDefault(
    useWorkerState("CostGroupClassificationsState"),
    []
  );

  const costGroupClassificationItems = useMemo(() => {
    return costGroupClassifications
      .filter((item) => item.classification === watchClassification)
      .map((item) => item.code);
  }, [costGroupClassifications, watchClassification]);

  const mkInputProps = (
    field: keyof ProjectFormValues,
    registerOptions?: RegisterOptions<ProjectFormValues, typeof field>
  ) => ({
    label: t(`form.${field}`),
    ...register(field, registerOptions),
  });

  const reportingPublishWarningTitle = t`publishReporting.title`;
  const reportingPublishWarningActions = useMemo(
    () => (
      <div className={"flex justify-between gap-4"}>
        <Button
          onClick={() => setReportingPublishWarningOpen(false)}
          variant={"text"}
          color={"normal"}
        >{t`publishReporting.cancel`}</Button>
        <Button
          onClick={() => {
            setReportingPublishWarningOpen(false);
            setValue("isReportingProject", true);
          }}
          variant={"primary"}
          color={"normal"}
        >{t`publishReporting.confirm`}</Button>
      </div>
    ),
    [setValue, t]
  );

  const reportingUnpublishWarningTitle = t`unpublishReporting.title`;
  const reportingUnpublishWarningActions = useMemo(
    () => (
      <div className={"flex justify-between gap-4"}>
        <Button
          onClick={() => setReportingUnpublishWarningOpen(false)}
          variant={"text"}
          color={"danger"}
        >{t`unpublishReporting.cancel`}</Button>
        <Button
          onClick={() => {
            setReportingUnpublishWarningOpen(false);
            setValue("isReportingProject", false);
          }}
          variant={"primary"}
          color={"danger"}
        >{t`unpublishReporting.confirm`}</Button>
      </div>
    ),
    [setValue, t]
  );

  const loading =
    projectGroupsLoading || currenciesLoading || defaultCurrencyLoading;

  return (
    <>
      {loading && <Loader testId={"basic-info-loader"} />}
      {!loading && (
        <div className="flex">
          <div className="grow basis-1/2 grid gap-y-3 pr-4">
            <Input
              {...mkInputProps("name", {
                minLength: {
                  value: 3,
                  message: t(`errors.tooShort`, { minLength: "3" }),
                },
                maxLength: {
                  value: 50,
                  message: t(`errors.tooLong`, { maxLength: "50" }),
                },
                required: { value: true, message: t`errors.required` },
              })}
              testId={"project-name-input"}
            />
            {errors.name && (
              // Have to use String() with useFormContext for now
              // https://github.com/react-hook-form/react-hook-form/issues/8653
              <ErrorMessage errorMessage={String(errors.name.message)} />
            )}
            <Input
              disabled={versionLoading}
              {...mkInputProps("code", {
                maxLength: {
                  value: 12,
                  message: t(`errors.tooLong`, { maxLength: "12" }),
                },
                required: { value: true, message: t`errors.required` },
                onBlur: (e) =>
                  // In case the user changed the code and then changed it back to the original value we reset the version to original
                  getFieldState("code").isDirty
                    ? setNextAvailableVersion(e.target.value)
                    : resetField("version"),
              })}
              testId={"project-code-input"}
            />
            {errors.code && (
              <ErrorMessage errorMessage={String(errors.code.message)} />
            )}
            <Controller
              control={control}
              name={"currency"}
              defaultValue={
                defaultCurrency?.defaultCurrency ??
                organizationCurrencies?.[0] ??
                "EUR"
              }
              render={({ field: { onChange, value } }) => (
                <StringCombobox
                  label={t`form.currency`}
                  items={organizationCurrencies ?? []}
                  onValueChange={onChange}
                  initialValue={value}
                  disabled={!newProject}
                  disabledTooltip={t`form.currencyDisabledTooltip`}
                />
              )}
            />
            <LicenseFeatureContainer feature={LicenseFeature.PROJECT_GROUPS}>
              <Controller
                control={control}
                name={"projectGroup"}
                render={({ field: { onChange, value } }) => (
                  <StringCombobox
                    label={t`form.projectGroup`}
                    items={projectGroups ?? []}
                    onValueChange={onChange}
                    initialValue={value}
                  />
                )}
              />
            </LicenseFeatureContainer>
            <div className={"flex flex-col"}>
              <LicenseFeatureContainer
                feature={LicenseFeature.PRODUCTION_PLANNING}
              >
                <Controller
                  control={control}
                  name={"productionPlanning"}
                  render={({ field: { value, onChange } }) => (
                    <Checkbox
                      checked={value}
                      setChecked={onChange}
                      label={t`form.productionPlanning`}
                      disabled={watchReporting}
                    />
                  )}
                />
              </LicenseFeatureContainer>
              <FeatureFlagContainer feature={publishToReportingFeatureFlag}>
                <LicenseFeatureContainer
                  feature={LicenseFeature.PUBLISH_TO_REPORTING}
                >
                  {!newProject && ccRights && (
                    <Controller
                      control={control}
                      name={"isReportingProject"}
                      render={({ field: { value } }) => (
                        <Checkbox
                          className={"mt-3"}
                          checked={value}
                          setChecked={() => {
                            value && setReportingUnpublishWarningOpen(true);
                            !value && setReportingPublishWarningOpen(true);
                          }}
                          label={t`form.publishToReporting`}
                          disabled={
                            watchProductionPlanning ||
                            projectDetails?.currency !== "EUR"
                          }
                          disabledTooltip={t`form.reportingDisabledTooltip`}
                        />
                      )}
                    />
                  )}
                </LicenseFeatureContainer>
              </FeatureFlagContainer>
              <FeatureFlagContainer feature={publishToTempoFeatureFlag}>
                {!newProject && (
                  <Controller
                    control={control}
                    name={"publishedToTempo"}
                    render={({ field: { value, onChange } }) => (
                      <Checkbox
                        className={"mt-3"}
                        checked={value}
                        setChecked={onChange}
                        label={t`form.publishToTempo`}
                      />
                    )}
                  />
                )}
              </FeatureFlagContainer>
            </div>
          </div>

          <div className="grow basis-2/5 grid gap-y-3 pr-4 self-start">
            <Input
              {...mkInputProps("version", {
                minLength: {
                  value: 1,
                  message: t(`errors.tooShort`, { minLength: "1" }),
                },
                maxLength: {
                  value: 3,
                  message: t(`errors.tooLong`, { maxLength: "3" }),
                },
                required: { value: true, message: t`errors.required` },
                validate: {
                  isNumber: (value) =>
                    isNumber(value) || t`errors.versionMustBeANumber`,
                  isPositive: (value) =>
                    isPositive(value) || t`errors.notPositive`,
                },
              })}
              testId={"version-input"}
            />
            {errors.version && (
              <>
                <ErrorMessage errorMessage={String(errors.version.message)} />
              </>
            )}
            <Input
              type="number"
              {...mkInputProps("taxRate", {
                max: {
                  value: 100,
                  message: t(`errors.maxValue`, { max: "100" }),
                },
                validate: {
                  isNumber: (value) => isNumber(value) || t`errors.notANumber`,
                  isPositive: (value) =>
                    isPositive(value) || t`errors.notPositive`,
                },
                valueAsNumber: true,
              })}
            />
            {errors.taxRate && (
              <ErrorMessage errorMessage={String(errors.taxRate.message)} />
            )}
            {newProject && (
              <>
                <Controller
                  control={control}
                  name={"classification"}
                  defaultValue={
                    defaultClassification
                      ? defaultClassification
                      : classifications[0]
                  }
                  render={({ field: { onChange, value } }) => (
                    <StringCombobox
                      label={t`form.classification`}
                      items={classifications}
                      onValueChange={(value) => {
                        resetField("costGroupClassification");
                        onChange(value);
                      }}
                      initialValue={getValidOrgClassification(value)}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name={"costGroupClassification"}
                  render={({ field: { onChange, value } }) => (
                    <StringCombobox
                      label={t`form.costGroupClassification`}
                      items={costGroupClassificationItems}
                      onValueChange={(value) => {
                        onChange(value);
                      }}
                      initialValue={value}
                    />
                  )}
                />
              </>
            )}
          </div>
          {reportingPublishWarningOpen && (
            <Modal
              isOpen={reportingPublishWarningOpen}
              closeModal={() => setReportingPublishWarningOpen(false)}
              width={420}
              title={reportingPublishWarningTitle}
              actions={reportingPublishWarningActions}
            >{t`publishReporting.description`}</Modal>
          )}
          {reportingUnpublishWarningOpen && (
            <Modal
              isOpen={reportingUnpublishWarningOpen}
              closeModal={() => setReportingUnpublishWarningOpen(false)}
              width={420}
              title={reportingUnpublishWarningTitle}
              actions={reportingUnpublishWarningActions}
            >{t`unpublishReporting.description`}</Modal>
          )}
        </div>
      )}
    </>
  );
};
