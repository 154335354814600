import {
  QueryClient,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from "react-query";
import axios from "../../../../axiosConfig";

export type ProjectManager = {
  pm: string;
};

export type EditProjectManager = {
  newPM: string;
  oldPM: string;
};

const queryKey = "projectManagers";

export function useGetProjectManagersQuery() {
  return useQuery<string[]>([queryKey], async () => {
    const res = await axios.get<string[]>("/getProjectManagers");
    return res.data;
  });
}

export function useEditProjectManagerQuery(): UseMutationResult<
  unknown,
  unknown,
  EditProjectManager
> {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (params) => {
      const { data } = await axios.post(`/editProjectManager`, params);
      return data;
    },
    onSettled: () => invalidateProjectManagerParams(queryClient),
  });
}

export function useAddProjectManagerQuery(): UseMutationResult<
  unknown,
  unknown,
  ProjectManager
> {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (params) => {
      const { data } = await axios.post(`/addProjectManager`, params);
      return data;
    },
    onSettled: () => invalidateProjectManagerParams(queryClient),
  });
}

export function useDeleteProjectManagerQuery(): UseMutationResult<
  unknown,
  unknown,
  ProjectManager
> {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (params) => {
      const { data } = await axios.post(`/deleteProjectManager`, params);
      return data;
    },
    onSettled: () => invalidateProjectManagerParams(queryClient),
  });
}

function invalidateProjectManagerParams(queryClient: QueryClient) {
  return queryClient.invalidateQueries({
    queryKey: [queryKey],
  });
}
