/* eslint-disable i18next/no-literal-string */
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { CurrentUserProvider } from "../auth/CurrentUserProvider";
import { SettingsContainer } from "../components/SettingsContainer";
import { VerticalViewContainer } from "../components/ViewContainer";
import { CrowdInEditor } from "../crowdin/CrowdInEditor";
import { DatadogLoggerProvider } from "../datadog/DatadogLoggerProvider";
import { DatadogRumProvider } from "../datadog/DatadogRumProvider";
import "../i18n/config";
import { licenseActiveReports } from "../licenseActiveReports";
import { licenseAgGrid } from "../licenseAgGrid";
import { LanguageProvider } from "../localize/LanguageProvider";
import { PostHogWrapper } from "../posthog/PostHogWrapper";
import { FeatureFlagContainer } from "../split/FeatureFlagContainer";
import { FeatureFlags } from "../split/FeatureFlags";
import { SplitConfiguration } from "../split/SplitConfiguration";
import { useProvideAppState } from "../utils/stateUtils";
import { AdminUsersSettings } from "./Admin/Settings/AdminUsersSettings";
import { OrganizationCurrencySettings } from "./Admin/Settings/OrganizationSettings/OrganizationCurrencySettings/OrganizationCurrencySettings";
import { OrganizationSettings } from "./Admin/Settings/OrganizationSettings/OrganizationSettings";
import { ReportDesignerContainer } from "./SuperAdmin/ReportDesigner/ReportDesignerContainer";
import { SuperAdminContainer } from "./SuperAdmin/SuperAdminContainer";
import { ProductFruitsInit } from "../productfruits/ProductFruitsInit";
import { OrganizationProjectManagersSettings } from "./Admin/Settings/OrganizationSettings/ProjectManagersSettings";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

licenseAgGrid();
licenseActiveReports();

/**
 * Root component of the TypeScript app.
 *
 * Responsible for:
 * - Calling hooks that set up app state
 * - Routing
 */
export const App_ = () => {
  const routes = [
    {
      path: "/new/superadmin",
      element: <SuperAdminContainer />,
    },
  ];

  const routesWithFullPageContainer = [
    {
      path: "/new/superadmin/report-templates/:reportName/edit/:projectId",
      element: <ReportDesignerContainer />,
    },
  ];

  const routesWithSettingsContainer = [
    {
      path: "/new/settings/organization/general",
      element: <OrganizationSettings />,
    },
    {
      path: "/new/settings/organization/currency",
      element: <OrganizationCurrencySettings />,
    },
    {
      path: "/new/settings/organization/projectManagers",
      element: <OrganizationProjectManagersSettings />,
    },

    {
      path: "/new/settings/users",
      element: <AdminUsersSettings />,
    },
  ];

  return (
    <main className="h-screen w-screen flex flex-col">
      <Routes>
        {routes.map(({ path, element }) => (
          <Route
            key={path}
            path={path}
            element={<VerticalViewContainer>{element}</VerticalViewContainer>}
          />
        ))}
        {routesWithFullPageContainer.map(({ path, element }) => (
          <Route key={path} path={path} element={element} />
        ))}
        {routesWithSettingsContainer.map(({ path, element }) => (
          <Route
            key={path}
            path={path}
            element={<SettingsContainer>{element}</SettingsContainer>}
          />
        ))}
        <Route path="/*" element={<NoMatch />} />
      </Routes>
    </main>
  );
};

/**
 * Proveide react contexts and similar global state needed.
 *
 * This is used to wrap the purescript app, which in turn wraps the
 * typescript one so we can run typescript components on both sides.
 */
export function AppWrapper<P extends object>(
  WrappedComponent: React.ComponentType<P>
) {
  return function AppWrapper_(props: P) {
    // Provides our app state and subscribes to worker state updates
    useProvideAppState();

    return (
      <CurrentUserProvider>
        <DatadogRumProvider>
          <DatadogLoggerProvider>
            <SplitConfiguration>
              <QueryClientProvider client={queryClient}>
                <ProductFruitsInit />
                <PostHogWrapper>
                  <LanguageProvider>
                    <BrowserRouter>
                      <FeatureFlagContainer feature={FeatureFlags.CROWDIN}>
                        <CrowdInEditor />
                      </FeatureFlagContainer>
                      <WrappedComponent {...props} />
                    </BrowserRouter>
                  </LanguageProvider>
                  <ReactQueryDevtools initialIsOpen={false} />
                </PostHogWrapper>
              </QueryClientProvider>
            </SplitConfiguration>
          </DatadogLoggerProvider>
        </DatadogRumProvider>
      </CurrentUserProvider>
    );
  };
}

/**
 * Wrap the real App component to workaround PureScript code not rendering this
 * correctly as a React component.
 *
 * This can eventually be thrown out once we're responsible of rendering
 * the actual React tree ourselves.
 */
export const App = () => {
  return <App_ />;
};

const NoMatch = () => {
  const location = useLocation();

  return (
    <div>
      <h3 className="m-4 text-xl">
        No match for route <code>{location.pathname}</code>
      </h3>
      <div className="m-4">
        The new React TypeScript app can be found under{" "}
        <a href="/new" className="text-primary underline">
          /new
        </a>
      </div>
    </div>
  );
};
